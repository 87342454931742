import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'hash', // 使用 hash 模式
  routes: [
    {
    	path: '/',
    	component: () => import('@/views/home/index'),
    	hidden: true
    },
		{
			path: '/login',
			component: () => import('@/views/login/index'),
			hidden: true
		}
  ]
});
 
export default router;