import Vue from 'vue';
import {
    Icon,
    Button,
		Popup,
		Dialog 
} from 'vant'
//按需引入
Vue.use(Icon);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Dialog);