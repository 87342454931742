<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App'
	}
</script>
<style>
	#app{
		width: 100%;
	}
</style>