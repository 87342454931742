import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false
// //引入所有组件&全局注册
// import Vant from 'vant'
import 'vant/lib/index.css'
import './lib/vant.js'
import 'amfe-flexible'
 
// import { resizeScale } from './resetSize.js'
// resizeScale()
new Vue({
	router,
  render: h => h(App),
}).$mount('#app')
